import { Button, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Role } from "../../models/role";
import { NewUser } from "../../models/new-user";
import { useDialog } from "../../provider/dialog-provider";
import { useService } from "../../provider/service-provider";
import { Branch } from "../../models/branch";

interface AddNewUserProps {
	handleSave: (newStock: NewUser) => void
}
const AddNewUser: FC<AddNewUserProps> = ({ handleSave }) => {

	const { roleService, branchService } = useService();
	const { handleCloseDialog } = useDialog();

	const [roles, setRoles] = useState<Role[]>([]);
	const [branchs, setBranchs] = useState<Branch[]>([]);

	const [fullName, setFullName] = useState('');
	const [username, setUsername] = useState('');
	const [branchId, setBranchId] = useState<number | string>('');
	const [roleId, setRoleId] = useState<number | string>('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		roleService.getAllRoles().then(setRoles);
		branchService.getAllBranchs().then(setBranchs);
	}, []);

	const handleClickSave = () => {
		if (password !== confirmPassword) {
			setError('Şifreler uyuşmuyor');
		} else {
			setError(null);
			handleSave({
				fullName,
				username,
				branchId: Number(branchId),
				roleId: Number(roleId),
				password
			});
		}
	}

	const isDisabled =
		!fullName ||
		!username ||
		!roleId ||
		!password ||
		!confirmPassword;

	return (
		<Stack spacing={2}>
			<Stack>
				<Typography variant="subtitle2">Adı Soyadı</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="fullName"
					placeholder="Adı Soyadı"
					value={fullName}
					onChange={(e) => setFullName(e.target.value)}
				/>
			</Stack>
			<Stack>
				<Typography variant="subtitle2">Kullanıcı Adı</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="username"
					placeholder="Kullanıcı Adı"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
				/>
			</Stack>
			<Stack>
				<Typography variant="subtitle2">Şube</Typography>
				<Select
					value={branchId}
					onChange={(e) => setBranchId(Number(e.target.value))}
				>
					{
						branchs.map((branch) => (
							<MenuItem value={branch.id}>{branch.name}</MenuItem>
						))
					}
				</Select>
			</Stack>
			<Stack>
				<Typography variant="subtitle2">Yetki Sınıfı</Typography>
				<Select
					value={roleId}
					onChange={(e) => setRoleId(Number(e.target.value))}
				>
					{
						roles.map((role) => (
							<MenuItem value={role.id}>{role.name}</MenuItem>
						))
					}
				</Select>
			</Stack>
			<Stack>
				<Typography variant="subtitle2">Şifre</Typography>
				<TextField
					fullWidth
					type="password"
					variant="outlined"
					name="password"
					placeholder="Şifre"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
			</Stack>
			<Stack>
				<Typography variant="subtitle2">Şifre Tekrar</Typography>
				<TextField
					fullWidth
					type="password"
					variant="outlined"
					name="confirmPassword"
					placeholder="Şifre Tekrar"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
				/>
			</Stack>

			{error && <Typography style={{ color: 'red' }}>{error}</Typography>}

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button variant="outlined" color="primary" sx={{ flex: 1 }} onClick={handleCloseDialog}>
					İptal
				</Button>
				<Button disabled={isDisabled} variant="contained" color="primary" sx={{ flex: 1 }} onClick={handleClickSave}>
					Kaydet
				</Button>
			</Stack>
		</Stack>
	)
}

export default AddNewUser;