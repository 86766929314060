import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import RecommendIcon from '@mui/icons-material/Recommend';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export enum ServiceStatus {
	AWAITING_SHIPMENT = "Sevk Bekliyor",
	IN_SERVICE = "Serviste",
	READY = "Hazır",
	DELIVERED = "Teslim Edildi"
}

export const getServiceStatusPriority = (serviceStatus: keyof typeof ServiceStatus): number => {
	switch (serviceStatus) {
		case 'AWAITING_SHIPMENT':
			return 1
		case 'IN_SERVICE':
			return 2;
		case 'READY':
			return 3;
		case 'DELIVERED':
			return 4;
	}
}

export const getServiceStatucIcon = (serviceStatus: keyof typeof ServiceStatus) => {
	switch (serviceStatus) {
		case 'AWAITING_SHIPMENT':
			return <AccessTimeIcon />;
		case 'IN_SERVICE':
			return <MiscellaneousServicesIcon />;
		case 'READY':
			return <RecommendIcon/>;
		case 'DELIVERED':
			return <DoneAllIcon/>;
		default:
			return;
	}
}

export const getServiceStatusColor = (serviceStatus: keyof typeof ServiceStatus) => {
	switch (serviceStatus) {
		case 'AWAITING_SHIPMENT':
			return "#BC0000";
		case 'IN_SERVICE':
			return "#007586";
		case 'READY':
			return "#E4B920";
		case 'DELIVERED':
			return "#3FA300";
		default:
			return;
	}
}