import { AxiosInstance } from "axios"
import { loginURL, logoutURL } from "../global"
import { User } from "../models/user"

export interface AuthServiceType {
	login: (username: string, password: string) => Promise<User>
	logout: () => Promise<void>
}

export const AuthService = ({ api }: { api: AxiosInstance }): AuthServiceType => {


	const login = async (username: string, password: string) => {
		const res = await api.post<{ username: string; password: string} , User>(loginURL, { username, password })
		localStorage.setItem("fullName", res.fullName);
		localStorage.setItem("role", res.role);
		localStorage.setItem("branch", res.branch);
		return res
	}

	const logout = async () => {
		return await api.post<void, void>(logoutURL).then(() => {
			localStorage.removeItem("fullName")
			localStorage.removeItem("role")
			localStorage.removeItem("branch")
		})
	}

	return {
		login,
		logout
	}

}