import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useService } from "../provider/service-provider";
import { useState } from "react";
import { useNavigate } from "react-router";
import background from '../assets/login-background.jpg';
import Logo from '../assets/logo.svg';

const Login = () => {

	const navigate = useNavigate();
	const { authService } = useService();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const [error, setError] = useState<string | null>(null);

	const handleLogin = () => {
		authService.login(username, password).then((res) => {
			
			navigate(res.role == "ADMIN" ? "/kullanici-yonetimi" : "/musteri-yonetimi")
		}).catch(() => {
			setError("Kullanıcı Adı veya Şifre Yanlış!")
		})
	};

	return (

		<Stack flexDirection="row" height="100%" sx={{ backgroundColor: "#fff" }}>

			<Stack flex={1} justifyContent="center" alignItems="center" height="100%" gap="20px" >

				<img src={Logo} width={200} />

				<Stack gap="30px" width={400} position="relative">

					<Stack gap="20px" width={400} position="relative">

						<Stack>
							<Typography ml={1} variant="subtitle2">Kullanıcı Adı</Typography>
							<TextField
								value={username}
								onChange={e => setUsername(e.target.value)}
							/>
						</Stack>

						<Stack>
							<Typography ml={1} variant="subtitle2">Şifre</Typography>
							<TextField
								value={password}
								onChange={e => setPassword(e.target.value)}
								type="password"
							/>
						</Stack>

						<Typography ml={1} position="absolute" bottom="-25px" variant="subtitle2" style={{ color: 'red' }}>{error}</Typography>
					</Stack>

					<Button
						variant="contained"
						color="primary"
						onClick={handleLogin}
					>
						Giriş Yap
					</Button>


				</Stack>
			</Stack>

			<Stack height="100%">

				<img
					height="100%"
					src={background}
				/>
			</Stack>

		</Stack>



	);
}

export default Login;