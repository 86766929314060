import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Role } from '../enums/role';

interface ProtectedRouteProps {
	roles: string[];
	children: ReactElement
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ roles, children }) => {
	const location = useLocation();
	const role = localStorage.getItem('role');

	if (!role || !roles.includes(role)) {
		return <Navigate to={role == Role.USER ? "/musteri-yonetimi" : "/"} state={{ from: location }} replace />;
	}

	return children;
};

export default ProtectedRoute;