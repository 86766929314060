import { Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useService } from "../../provider/service-provider";

interface AlternativeModelsProps {
	productCode: string
}
const AlternativeModels: FC<AlternativeModelsProps> = ({ productCode }) => {

	const { vehiclePartService } = useService();

	const [models, setModels] = useState<string[]>([]);

	useEffect(() => {
		vehiclePartService.getAlternativeModelsByCode(productCode).then(setModels)
	}, []);

	return (
		<Stack>
			{models.map((model) => (
				<Typography>{model}</Typography>
			))}
		</Stack>
	)
}

export default AlternativeModels;