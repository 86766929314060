import { Box, Divider, Typography } from "@mui/material";
import { FC } from "react";

const PageHeader: FC<{ title: string }> = ({ title }) => {

	return (
		<>
			<Box p="10px">
				<Typography fontSize="20px" fontWeight={600}>{title}</Typography>
			</Box>
			<Divider flexItem />
		</>
	);
};

export default PageHeader;