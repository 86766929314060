import { FC, useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useDialog } from "../../provider/dialog-provider";
import { NewCategory } from "../../models/new-category";

interface AddNewCategoryProps {
	handleSave: (category: NewCategory) => void
}
const AddNewCategory: FC<AddNewCategoryProps> = ({ handleSave }) => {

	const { handleCloseDialog } = useDialog();

	const [categoryName, setCategoryName] = useState("");

	const handleClickSave = () => {
		handleSave({ name: categoryName });
	};

	return (
		<Stack spacing={2}>
			<Stack>
				<Typography variant="subtitle2">Adı</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="fullName"
					placeholder="Adı"
					value={categoryName}
					onChange={(e) => setCategoryName(e.target.value)}
				/>
			</Stack>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button variant="outlined" onClick={handleCloseDialog}>
					İptal
				</Button>
				<Button disabled={!categoryName} variant="contained" onClick={handleClickSave}>
					Kaydet
				</Button>
			</Stack>
		</Stack>
	);
}

export default AddNewCategory;