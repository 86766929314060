import { AxiosInstance } from "axios";
import { createCustomerURL, deleteCustomerURL, getAllCustomersURL, getNamesAllCustomerssURL } from "../global";
import { Customer } from "../models/customer.model";
import { NewCustomer } from "../models/new-customer.model";
import { UpdatedCustomer } from "../models/update-customer.model";
import { CustomerName } from "../models/customer-name.model";

export interface CustomerServiceType {
	getAllCustomers: () => Promise<Customer[]>
	getNames: () => Promise<CustomerName[]>
	createCustomer: (payload: NewCustomer) => Promise<void>
	updateCustomer: (payload: UpdatedCustomer) => Promise<void>
	deleteCustomer: (id: number) => Promise<void>
}

export const CustomerService = ({ api }: { api: AxiosInstance }): CustomerServiceType => {

	const getAllCustomers = () => {
		return api.get<void, Customer[]>(getAllCustomersURL);
	}

	const getNames = () => {
		return api.get<void, CustomerName[]>(getNamesAllCustomerssURL);
	}

	const createCustomer = (payload: NewCustomer) => {
		return api.post<NewCustomer, void>(createCustomerURL, payload);
	}

	const updateCustomer = (payload: UpdatedCustomer) => {
		return api.put<UpdatedCustomer, void>(createCustomerURL, payload);
	}

	const deleteCustomer = (id: number) => {
		return api.delete<void, void>(`${deleteCustomerURL}/${id}`);
	}


	return {
		getAllCustomers,
		getNames,
		createCustomer,
		updateCustomer,
		deleteCustomer
	}

}