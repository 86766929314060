import React, { FC, RefObject, useEffect, useRef } from 'react';
import { Container, TextField, Button, Typography, Grid, Box, Paper, Stack, Divider, Card, CardContent } from '@mui/material';
import { ServiceForm } from '../../models/service-form.model';
import { useReactToPrint } from 'react-to-print';
import { useDialog } from '../../provider/dialog-provider';
import dayjs from 'dayjs';
import bajajImage from "../../assets/print-photo/bajaj.png";
import kanuniImage from "../../assets/print-photo/kanuni.png";
import michelinImage from "../../assets/print-photo/michelin.jpg";
import peugeotImage from "../../assets/print-photo/peugeot.png";
import symImage from "../../assets/print-photo/sym.png";

interface ServiceFormPrintDetailProps {
  serviceForm: ServiceForm
}

const ServiceFormPrintDetail: FC<ServiceFormPrintDetailProps> = ({ serviceForm }) => {

  const { handleCloseDialog } = useDialog();

  const printComponentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: handleCloseDialog
  });

  useEffect(handlePrint, []);

  return (
    <div ref={printComponentRef}>
      <Stack width="210mm" height="297mm" padding={5} gap={3}>

        <Stack>
          <Grid container justifyContent="center" spacing={3}>
            {/* Görsel 1 */}
            <Grid item xs={2}>
              <Box
                component="img"
                sx={{ width: '100%', height: 'auto' }}
                src={bajajImage}
                alt="Image 1"
              />
            </Grid>
            {/* Görsel 2 */}
            <Grid item xs={2}>
              <Box
                component="img"
                sx={{ width: '100%', height: 'auto' }}
                src={kanuniImage}
                alt="Image 2"
              />
            </Grid>
            {/* Görsel 3 */}
            <Grid item xs={2}>
              <Box
                component="img"
                sx={{ width: '100%', height: 'auto' }}
                src={michelinImage}
                alt="Image 3"
              />
            </Grid>
            {/* Görsel 4 */}
            <Grid item xs={2}>
              <Box
                component="img"
                sx={{ width: '100%', height: 'auto' }}
                src={peugeotImage}
                alt="Image 4"
              />
            </Grid>
            <Grid item xs={2}>
              <Box
                component="img"
                sx={{ width: '100%', height: 'auto' }}
                src={symImage}
                alt="Image 5"
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100px"
            width="100%"
          >
            <Typography fontSize={40} fontWeight="bold">
              YAŞAR TİCARET
            </Typography>
          </Box>
        </Stack>
        <Stack>
          <Typography fontSize={35} fontWeight={900} color="#1f3b62">
            SERVİS KABUL FORMU
          </Typography>
        </Stack>

        <Stack direction="row" gap={5} justifyContent="space-between">
          <Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Müşteri Adı Soyadı:</Typography>
              <Typography>{serviceForm.customer.fullName}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Adres:</Typography>
              <Typography>{serviceForm.customer.address}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Tel:</Typography>
              <Typography>{serviceForm.customer.phoneNumber}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">E-mail:</Typography>
              <Typography>{serviceForm.customer.email}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Garanti Kapsamı:</Typography>
              <Typography>{serviceForm.chassisNumber ? "Evet" : "Hayır"}</Typography>
            </Stack>
          </Stack>

          <Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Plaka No:</Typography>
              <Typography>{serviceForm.vehicle.licensePlate} {serviceForm.chassisNumber ? "- " + serviceForm.chassisNumber : null}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Marka:</Typography>
              <Typography>{serviceForm.vehicle.brand}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Model:</Typography>
              <Typography>{serviceForm.vehicle.model}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Km:</Typography>
              <Typography>{serviceForm.mileage}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={700} color="#1f3b62">Aracın Servise Giriş Tarihi:</Typography>
              <Typography>{dayjs(serviceForm.serviceDate).format("DD.MM.YYYY HH:mm")}</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" gap={1}>
          <Stack flex={6}>
            <Stack >
              <Typography fontWeight={700} color="white" width="100%" style={{ background: "#1f3b62", borderRadius: 10, padding: 5, textAlign: "center" }}>Müşterinin Belirttiği Hususlar</Typography>
              <Typography padding={1}>
                {/* {serviceForm.customerNotes.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))} */}
                {serviceForm.customerNotes?.split('\n').join(', ')}
              </Typography>
            </Stack>
          </Stack>
          <Stack flex={2} gap={2}>
            <Stack mb={3}>
              <Typography fontWeight={700} color="white" width="100%" style={{ backgroundColor: "#1f3b62", borderRadius: 10, padding: 5, textAlign: "center" }}>Yetkili Servis</Typography>
              <Typography fontWeight={700} color="#1f3b62" width="100%" style={{ textAlign: "center" }}>KAŞE</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" gap={1}>
          <Stack flex={6}>
            <Stack>
              <Typography fontWeight={700} color="white" width="100%" style={{ backgroundColor: "#1f3b62", borderRadius: 10, padding: 5, textAlign: "center" }}>Servisin Tespiti ve Yapılan İşlemler</Typography>
              <Typography padding={1}>
                {/* {serviceForm.notes.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))} */}
                {serviceForm.notes?.split('\n').join(', ')}
              </Typography>
            </Stack>
          </Stack>
          <Stack flex={2} gap={2}>
            <Stack>
              <Typography fontWeight={700} color="white" width="100%" style={{ backgroundColor: "#1f3b62", borderRadius: 10, padding: 5, textAlign: "center" }}>Tahmini Teslim Tarihi</Typography>
              <Typography fontWeight={400} color="#1f3b62" width="100%" style={{ backgroundColor: "#bfdaf8", borderRadius: 10, padding: 5, textAlign: "center" }}>....../....../20</Typography>
            </Stack>
            <Stack>
              <Typography fontWeight={700} color="white" width="100%" style={{ backgroundColor: "#1f3b62", borderRadius: 10, padding: 5, textAlign: "center" }}>Tahmini Toplam Ücret</Typography>
              <Typography fontWeight={400} color="#1f3b62" width="100%" style={{ backgroundColor: "#bfdaf8", borderRadius: 10, padding: 5, textAlign: "center" }}>................</Typography>
            </Stack>
            <Stack>
              <Typography fontWeight={700} color="white" width="100%" style={{ backgroundColor: "#1f3b62", borderRadius: 10, padding: 5, textAlign: "center" }}>Alınan Ücret</Typography>
              <Typography fontWeight={400} color="#1f3b62" width="100%" style={{ backgroundColor: "#bfdaf8", borderRadius: 10, padding: 5, textAlign: "center" }}>................</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack >
          <Stack direction="row" gap={1}>
            <Typography fontWeight={700} color="#1f3b62">Aracı Teslim Alan Servis:</Typography>
            <Typography>{serviceForm.user.branch}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight={700} color="#1f3b62">Yetkili Kişi:</Typography>
            <Typography>{serviceForm.user.fullName}</Typography>
          </Stack>
        </Stack>
        <Divider style={{ width: '100%', background: '#1f3b62' }} />

        <Stack direction="row" justifyContent="space-between" mt={1} gap={15}>
          <Stack gap={1} style={{ border: '1px solid #1f3b62', borderRadius: '8px', padding: '10px', boxShadow: '2px', flex: '1' }}>
            <Typography fontWeight={500} color="#1f3b62">Servisinize bıraktığım aracımı çalışır vaziyette ve
              eksiksiz olarak teslim aldım.
            </Typography>
            <Typography fontWeight={700} color="#1f3b62" style={{ textAlign: "center" }}>Tarih: {dayjs(serviceForm.serviceDate).format("DD / MM / YYYY")}</Typography>
            <Typography fontWeight={700} color="#1f3b62" mt={1}>
              Müşteri Adı Soyadı:
            </Typography>

            <Typography fontWeight={700} color="#1f3b62" mt={1}>
              Müşteri İmzası:
            </Typography>
          </Stack>


          <Stack gap={1} style={{ border: '1px solid #1f3b62', borderRadius: '8px', padding: '10px', boxShadow: '2px', flex: '1' }}>
            <Typography fontWeight={500} color="#1f3b62">Aracı teslim eden teknisyen, belirtilen hususları kontrol edip müşteriye bilgi vermiştir.
            </Typography>
            <Typography fontWeight={700} color="#1f3b62" mt={2}>
              Yetkili Kişi:
            </Typography>
            <Typography fontWeight={700} color="#1f3b62" mt={1}>
              Yetkili İmzası:
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography fontWeight={300} style={{ textAlign: "center" }}>Bizleri tercih ettğiniz için teşekkür eder, güvenli sürüşler dileriz.</Typography>
        </Stack>
      </Stack>


    </div>
  );
};

export default ServiceFormPrintDetail;
