import { AxiosInstance } from "axios"
import { createModelURL, deleteModelURL, getAllModelsURL } from "../global"
import { Model } from "../models/model"
import { NewModel } from "../models/new-model"

export interface ModelServiceType {
	getAllModels: () => Promise<Model[]>
	createModel: (payload: NewModel) => Promise<void>
	deleteModel: (id: number) => Promise<void>
	getModelsByBrandId: (brandId: number) => Promise<Model[]>
}

export const ModelService = ({ api }: { api: AxiosInstance }): ModelServiceType => {

	const getAllModels = () => {
		return api.get<void, Model[]>(getAllModelsURL);
	}

	const createModel = (payload: NewModel) => {
		return api.post<NewModel, void>(createModelURL, payload);
	}

	const deleteModel = (id: number) => {
		return api.delete<void, void>(`${deleteModelURL}/${id}`);
	}

	const getModelsByBrandId = (brandId: number) => {
		return api.get<void, Model[]>(`${getAllModelsURL}/${brandId}`);
	}

	return {
		getAllModels,
		createModel,
		deleteModel,
		getModelsByBrandId
	}

}