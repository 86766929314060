import DangerousIcon from '@mui/icons-material/Dangerous';
import { Stack, Typography } from '@mui/material';


const Error = () => {
	return (
		<Stack alignItems="center" justifyContent="center" height="100%">
			<DangerousIcon sx={{fontSize: 60, color: "#b42121"}}/>
			<Typography variant='h4'>Bir Sorun Oluştu</Typography>
			<Typography>Üzgünüz, bir hata meydana geldi. Lütfen daha sonra tekrar deneyin.</Typography>
		</Stack>
	);
}

export default Error;