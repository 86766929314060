import { AxiosInstance } from "axios"
import { Branch } from "../models/branch"
import { getAllBranchsURL } from "../global"

export interface BranchServiceType {
	getAllBranchs: () => Promise<Branch[]>
}

export const BranchService = ({ api }: { api: AxiosInstance }): BranchServiceType => {

	const getAllBranchs = () => {
		return api.get<void, Branch[]>(getAllBranchsURL);
	}

	return {
		getAllBranchs,
	}

}