import { createContext, FC, ReactNode, useContext } from "react";
import { useApi } from "./api-provider";
import { CustomerService, CustomerServiceType } from "../service/customer-service";
import { VehicleService, VehicleServiceType } from "../service/vehicle-service";
import { ServiceFormService, ServiceFormServiceType } from "../service/service-form-service";
import { BrandService, BrandServiceType } from "../service/brand-service";
import { CategoryService, CategoryServiceType } from "../service/category-service";
import { ShelfService, ShelfServiceType } from "../service/shelf-service";
import { ModelService, ModelServiceType } from "../service/model-service";
import { StockService, StockServiceType } from "../service/stock-service";
import { UserService, UserServiceType } from "../service/user-service";
import { RoleService, RoleServiceType } from "../service/role-service";
import { AuthService, AuthServiceType } from "../service/auth-service";
import { VehiclePartService, VehiclePartServiceType } from "../service/vehicle-part-service";
import { BranchService, BranchServiceType } from "../service/brach-service";

interface ServiceContextType {
	customerService: CustomerServiceType
	vehicleService: VehicleServiceType
	serviceFormService: ServiceFormServiceType
	brandService: BrandServiceType
	categoryService: CategoryServiceType
	shelfService: ShelfServiceType
	modelService: ModelServiceType
	stockService: StockServiceType
	userService: UserServiceType
	roleService: RoleServiceType
	authService: AuthServiceType
	vehiclePartService: VehiclePartServiceType
	branchService: BranchServiceType
}

const ServiceContext = createContext<ServiceContextType | null>(null);


export const ServiceProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { api } = useApi();

    /* Services */

    const customerService = CustomerService({ api });
	const vehicleService = VehicleService({ api });
	const serviceFormService = ServiceFormService({ api });
	const brandService = BrandService({ api });
	const categoryService = CategoryService({ api });
	const shelfService = ShelfService({ api });
	const modelService = ModelService({ api });
	const stockService = StockService({ api });
	const userService = UserService({ api });
	const roleService = RoleService({ api });
	const authService = AuthService({ api });
	const vehiclePartService = VehiclePartService({ api });
	const branchService = BranchService({ api });

    const contextValues: ServiceContextType = {
        customerService,
		vehicleService,
		serviceFormService,
		brandService,
		categoryService,
		shelfService,
		modelService,
		stockService,
		userService,
		roleService,
		authService,
		vehiclePartService,
		branchService
    }

    return (
        <ServiceContext.Provider value={contextValues}>
            {children}
        </ServiceContext.Provider>
    );
};

export const useService = (): ServiceContextType => {
    const context = useContext(ServiceContext);
    if (!context) {
        throw new Error('useService must be used within a ServiceProvider');
    }
    return context;
};