import { Stack } from "@mui/material";
import NavBar from "./nav-bar";
import Routing from "../routes/routing";
import { Outlet } from "react-router";

const Layout = () => {
	return (
		<Stack height="100%" flexDirection="row">
			{/* Navigation Bar */}
			<NavBar/>
			{/* Body */}
			<Stack sx={{ padding:"20px 20px 20px 0px", flex: 1, height: "100%" }}>
					<Stack sx={{ backgroundColor: "white",  flex: 1, height: "100%", borderRadius: "20px", border: "1px #E8EAEC", padding: "10px"}}>
						<Outlet/>
					</Stack>
			</Stack>
		</Stack>
	);
}

export default Layout;