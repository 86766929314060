import { createTheme } from "@mui/material";

export const theme = createTheme({
	palette: {
		background: {
			default: '#E8EBED',
		},
		primary: {
			main: "#000"
		},
		info: {
			main: "#064568"
		}
	},
	typography: {
		fontSize: 13,
		fontFamily: 'Inter, Arial, sans-serif',
	},
	components: {
		MuiTextField: {
		  styleOverrides: {
			root: {
			  borderRadius: '8px',
			  fontSize: "14px"
			},
		  },
		},
		MuiOutlinedInput: {
		  styleOverrides: {
			root: {
			  borderRadius: '8px',
			  fontSize: "14px"
			},
			inputTypeSearch: {
				padding: '10.5px 14px 10.5px 0px'
			},
			input: {
				padding: '10.5px 14px 10.5px 14px'
			},
			multiline: {
				padding: '5.25px 7px 5.25px 7px'
			}
		  },
		},
		MuiCircularProgress: {
			styleOverrides: {
				root: {
					width: "100%",
					height: "100%"
				}
			}
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontSize: "14px"
				}
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: "14px"
				}
			}
		}
	  },
});