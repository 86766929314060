import { AxiosInstance } from "axios"
import { User } from "../models/user"
import { NewUser } from "../models/new-user"
import { createUserURL, deleteUserURL, getAllUsersURL } from "../global"

export interface UserServiceType {
	getAllUsers: () => Promise<User[]>
	createUser: (payload: NewUser) => Promise<void>
	deleteUser: (username: string) => Promise<void>
}

export const UserService = ({ api }: { api: AxiosInstance }): UserServiceType => {

	const getAllUsers = () => {
		return api.get<void, User[]>(getAllUsersURL);
	}

	const createUser = (payload: NewUser) => {
		return api.post<NewUser, void>(createUserURL, payload);
	}

	const deleteUser = (username: string) => {
		return api.delete<void, void>(`${deleteUserURL}/${username}`);
	}


	return {
		getAllUsers,
		createUser,
		deleteUser
	}

}