import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { useApi } from '../../provider/api-provider';
import { Box, Typography } from '@mui/material';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	'--DataGrid-containerBackground': "white",
	'& .MuiDataGrid-cell:focus-within': {
		outline: 'none'
	},
	border: 'none'
}));

const CustomDataGrid = ({ ...props }: DataGridProps) => {

	return (

		props.rows && props.rows.length > 0 ? (
			<StyledDataGrid {...props} />
		) : (
			<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
				<Typography color="textSecondary">
					Kayıt bulunamadı.
				</Typography>
			</Box>
		)

	);
}

export default CustomDataGrid;