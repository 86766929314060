import { Box, IconButton, Stack, Typography } from "@mui/material";
import { FC, useState } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { ServiceForm } from "../../models/service-form.model";
import { getServiceStatusColor, ServiceStatus } from "../../enums/service-status.enum";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useDialog } from "../../provider/dialog-provider";
import CustomConfirmation from "../shared/custom-confimation";
import ServiceFormDetail from "./service-form-detail";
import { Role } from "../../enums/role";
import UpdateStatusServiceForm from "./update-service-form";
import { useService } from "../../provider/service-provider";
import { UpdateServiceForm } from "../../models/update-service-form.model";

interface DraggablePlateProps {
	provided: DraggableProvided
	serviceForm: ServiceForm
	serviceStatus: string
	deleteServiceForm: (id: number) => void
	getAllServiceForms: () => void
}
const DraggablePlate: FC<DraggablePlateProps> = ({ provided, serviceForm, serviceStatus, deleteServiceForm, getAllServiceForms }) => {

	const { handleOpenDialog, handleCloseDialog } = useDialog();
	const { serviceFormService } = useService();

	const [isHover, setIsHover] = useState(false);

	const openConfirmationDialog = () => {
		handleOpenDialog(<CustomConfirmation handleClickConfirm={() => deleteServiceForm(serviceForm.id)} />, {
			headerTitle: "Emin Misiniz?",
			haveCloseIcon: true
		});
	}

	const openServiceFormDetail = () => {
		handleOpenDialog(<UpdateStatusServiceForm serviceForm={serviceForm} handleSave={updateServiceForm} />, {
			headerTitle: "Servis Form Detayı",
			haveCloseIcon: true
		});
	}

	const updateServiceForm = (serviceForm: UpdateServiceForm) => {
		serviceFormService.updateServiceForm(serviceForm).then(() => {
			handleCloseDialog();
			getAllServiceForms();
		});
	}

	return (
		<Stack onClick={openServiceFormDetail} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
			sx={{
				...provided.draggableProps.style,
				borderRadius: "10px",
				border: "2px #000",
				boxShadow: "1px 1px 10px 0px rgba(0, 0, 0, 0.25)",
				userSelect: 'none',
				flexDirection: "row",
				marginTop: "10px"
			}}
		>
			<Stack padding="10px" justifyContent={isHover ? "space-between": "flex-end"} sx={{ background: getServiceStatusColor(serviceStatus as keyof typeof ServiceStatus), boxShadow: "2px 4px 3.5px 1px rgba(0, 0, 0, 0.25)", borderRadius: "10px 0 0 10px" }}>
				{
					isHover && localStorage.getItem("role") == Role.ADMIN && (
						<IconButton
							sx={{ color: "#fff" , width: "20px"}}
							onClick={e => {e.preventDefault(); e.stopPropagation(); openConfirmationDialog()}}
						>
							<DeleteOutlineOutlinedIcon />
						</IconButton>
					)
				}
				<Typography fontWeight={700} color="#fff">TR</Typography>
			</Stack>
			<Stack paddingY="15px" paddingX="10px" gap="10px" width="100%">
				<Typography fontWeight={700} fontSize={24} color="#00031B" textAlign="center">{serviceForm.vehicle.licensePlate}</Typography>
				<Stack flexDirection="row" justifyContent="space-between">
					<Typography lineHeight={1} fontWeight={400} fontSize={13} color="#00031B">{serviceForm.customer.fullName}</Typography>
					<Typography lineHeight={1} fontWeight={300} fontSize={12} color="#7B7B7B" textAlign="right">{`${serviceForm.vehicle.brand} ${serviceForm.vehicle.model}`}</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
}

export default DraggablePlate;