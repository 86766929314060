import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "../shared/custom-data-grid";
import { FC, useEffect, useState } from "react";
import { Brand } from "../../models/brand";
import { useService } from "../../provider/service-provider";
import { IconButton, Stack, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomAddButton from "../shared/custom-add-button";
import { NewBrand } from "../../models/new-brand";
import { useDialog } from "../../provider/dialog-provider";
import AddNewBrand from "./add-new-brand";
import CustomConfirmation from "../shared/custom-confimation";
import { Role } from "../../enums/role";

const BrandTable = () => {

	const { brandService } = useService();
	const { handleOpenDialog, handleCloseDialog } = useDialog();

	const [brands, setBrands] = useState<Brand[]>([]);

	const getAllBrands = () => {
		brandService.getAllBrands().then(setBrands)
	}

	const createBrand = (newBrand: NewBrand) => {
		brandService.createBrand(newBrand).then(() => {
			handleCloseDialog();
			getAllBrands();
		});
	}

	useEffect(getAllBrands, []);

	const openConfirmationDialog = (id: number) => {
		handleOpenDialog(<CustomConfirmation handleClickConfirm={() => deleteBrand(id)} subtitle="Bu markaya bağlı stok kayıtları da silinecektir." />, {
			headerTitle: "Emin Misiniz?",
			haveCloseIcon: true
		});
	}

	const deleteBrand = (id: number) => {
		brandService.deleteBrand(id).then(() => {
			handleCloseDialog();
			getAllBrands();
		});
	}

	const openNewBrandDialog = () => {
		handleOpenDialog(<AddNewBrand handleSave={createBrand} />, {
			headerTitle: "Yeni Marka Ekle",
			haveCloseIcon: true
		});
	}

	const columns: GridColDef[] = [
		{ field: 'id', headerName: "#", width: 70 },
		{ field: 'name', headerName: "Marka", width: 200 },
		{
			field: "actions", headerName: "", flex: 1,
			renderCell: (params) => (
				<Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" height="100%">
					{localStorage.getItem("role") == Role.ADMIN && (
						<IconButton
							color="error"
							onClick={() => openConfirmationDialog(params.row.id)}
						>
							<DeleteOutlineOutlinedIcon />
						</IconButton>
					)}
				</Stack>
			),
		}
	];

	return (
		<>
			<Stack flexDirection="row" justifyContent="space-between">
				<Typography fontSize="20px" fontWeight={600}>Markalar</Typography>
				<CustomAddButton title="Ekle" onClick={openNewBrandDialog} />
			</Stack>
			<CustomDataGrid
				columns={columns}
				rows={brands}
				disableColumnMenu
				disableColumnResize
				disableRowSelectionOnClick
				initialState={{
					pagination: {
						paginationModel: { pageSize: 4 },
					},
				}}
			/>
		</>
	);
}

export default BrandTable;