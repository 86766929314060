import { FC, useEffect, useState } from "react";
import CustomDataGrid from "../shared/custom-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import { IconButton, Stack } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useService } from "../../provider/service-provider";
import CustomAddButton from "../shared/custom-add-button";
import { useDialog } from "../../provider/dialog-provider";
import AddNewVehicle from "./add-new-vehicle";
import { VehicleModel } from "../../models/vehicle.model";
import CustomConfirmation from "../shared/custom-confimation";
import { Role } from "../../enums/role";

interface VehiclesProps {
	customerId: number
}
const Vehicles: FC<VehiclesProps> = ({ customerId }) => {

	const { handleOpenDialog, handleCloseDialog } = useDialog();
	const { vehicleService } = useService();

	const [vehicles, setVehicles] = useState<VehicleModel[]>([]);

	const deleteVehicle = (id: number) => {
		vehicleService.deleteVehicle(id).then(() => {
			openVehicleDialog(customerId);
			getVehicles();
		});
	}

	const openVehicleDialog = (customerId: number) => {
		handleOpenDialog(<Vehicles customerId={customerId} />, {
			headerTitle: "Araçlar",
			haveCloseIcon: true
		});
	}

	const getVehicles = () => {
		vehicleService.getAllVehicles(customerId).then(setVehicles);
	}

	useEffect(getVehicles, []);

	const openAddNewVehicleDialog = () => {
		handleOpenDialog(<AddNewVehicle customerId={customerId} />, {
			headerTitle: "Yeni Araç Ekle",
			haveCloseIcon: true
		});
	}

	const openConfirmationDialog = (id: number) => {
		handleOpenDialog(<CustomConfirmation handleClickConfirm={() => deleteVehicle(id)} handleClickCancel={() => openVehicleDialog(customerId)} subtitle="Bu araca bağlı olan servis formları da silinecektir." />, {
			headerTitle: "Emin Misiniz?",
			haveCloseIcon: true
		});
	}

	const columns: GridColDef[] = [
		{ field: "licensePlate", headerName: "Plaka", flex: 1 },
		{ field: "brand", headerName: "Marka", flex: 1 },
		{ field: "model", headerName: "Model", flex: 1 },
		{ field: "year", headerName: "Yıl", flex: 1 },
		{ field: "color", headerName: "Renk", flex: 1 },
		{
			field: "actions", headerName: "", flex: 1,
			renderCell: (params) => (
				<Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" height="100%">
					{localStorage.getItem("role") == Role.ADMIN && (
						<IconButton
							color="error"
							onClick={() => openConfirmationDialog(params.row.id)}
						>
							<DeleteOutlineOutlinedIcon />
						</IconButton>
					)}
				</Stack>
			),
		}
	]

	return (
		<Stack gap="20px">
			<Stack alignItems="flex-end">
				<CustomAddButton title="Yeni Araç Ekle" onClick={openAddNewVehicleDialog} />
			</Stack>
			<CustomDataGrid
				columns={columns}
				rows={vehicles}
				disableColumnMenu
				disableColumnResize
				disableRowSelectionOnClick
				hideFooter
				getRowId={(row) => row.licensePlate}
			/>
		</Stack>
	);
}

export default Vehicles;