import { Button, Stack, Typography } from "@mui/material";
import { useDialog } from "../../provider/dialog-provider";
import { FC } from "react";


interface CustomConfirmationProps {
	subtitle?: string
	handleClickConfirm: () => void
	handleClickCancel?: () => void
}
const CustomConfirmation: FC<CustomConfirmationProps> = ({subtitle, handleClickConfirm, handleClickCancel}) => {

	const { handleCloseDialog } = useDialog();

	return (
		<Stack gap="20px">
			<Typography>Bu öğeyi silmek istediğinizden emin misiniz? {subtitle}</Typography>
			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button sx={{ width: "100px" }} variant="outlined" onClick={handleClickCancel || handleCloseDialog}>
					İptal
				</Button>
				<Button sx={{ width: "100px" }} variant="contained" onClick={handleClickConfirm}>
					Onayla
				</Button>
			</Stack>
		</Stack>
	);
}

export default CustomConfirmation;