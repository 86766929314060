import { createContext, FC, ReactElement, ReactNode, useContext, useState } from "react";
import CustomDialog, { CustomDialogData } from "../components/shared/custom-dialog";

interface DialogContextType {
	handleOpenDialog: (ModalComponent: ReactElement, modalData?: CustomDialogData) => void
	handleCloseDialog: () => void
}
const DialogContext = createContext<DialogContextType | null>(null);

export const DialogProvider: FC<{ children: ReactNode }> = ({ children }) => {

	const [open, setOpen] = useState(false);
	const [DialogComponent, setDialogComponent] = useState<ReactElement | null>(null);

	const [customModalData, setCustomModalData] = useState<CustomDialogData>();

	const handleOpenDialog = (ModalComponent: ReactElement, modalData?: CustomDialogData) => {
		modalData && setCustomModalData(modalData);
		setDialogComponent(ModalComponent);
		setOpen(true);
	}

	const handleCloseDialog = () => {
		setOpen(false);
		setDialogComponent(null);
		setCustomModalData(undefined);
	}

	const contextValue: DialogContextType = {
		handleOpenDialog,
		handleCloseDialog
	};

	return (
		<DialogContext.Provider value={contextValue}>
			<CustomDialog open={open} DialogComponent={DialogComponent} data={customModalData} onClose={handleCloseDialog} />
			{children}
		</DialogContext.Provider>
	);

};


export const useDialog = () => {
	const context = useContext(DialogContext);
	if (!context) {
		throw new Error('useDialog must be used within a DialogProvider');
	}
	return context;
};