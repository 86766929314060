import { FC, useEffect, useState } from "react";
import { NewStock } from "../../models/new-stock.model";
import { Button, IconButton, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Brand } from "../../models/brand";
import { Model } from "../../models/model";
import { Category } from "../../models/category";
import { Shelf } from "../../models/shelf";
import { useDialog } from "../../provider/dialog-provider";
import { useService } from "../../provider/service-provider";
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

interface AddNewStockProps {
	handleSave: (newStock: NewStock) => void
}
const AddNewStock: FC<AddNewStockProps> = ({ handleSave }) => {

	const { handleCloseDialog } = useDialog();
	const { modelService, brandService, categoryService, shelfService, vehiclePartService } = useService();

	const [brands, setBrands] = useState<Brand[]>([]);
	const [models, setModels] = useState<Model[]>([]);
	const [categories, setCategories] = useState<Category[]>([]);
	const [shelfs, setShelfs] = useState<Shelf[]>([]);

	useEffect(() => {
		brandService.getAllBrands().then(setBrands)
		categoryService.getAllCategories().then(setCategories)
		shelfService.getAllShelfs().then(setShelfs)
		modelService.getAllModels().then(setModels)
	}, []);

	const [productCode, setProductCode] = useState('');
	const [productName, setProductName] = useState('');
	const [brandId, setBrandId] = useState<number | string>('');
	const [modelId, setModelId] = useState<number | string>('');
	const [categoryId, setCategoryId] = useState<number | string>('');
	const [shelfId, setShelfId] = useState<number | string>('');
	const [quantityInStock, setQuantityInStock] = useState<number | string>('');
	const [purchasePrice, setPurchasePrice] = useState<number | string>('');

	const handleClickSave = () => {
		handleSave({
			productCode,
			productName,
			brandId: Number(brandId),
			modelId: Number(modelId),
			categoryId: Number(categoryId),
			shelfId: Number(shelfId),
			quantityInStock: Number(quantityInStock),
			purchasePrice: Number(purchasePrice)
		});
	};

	const getVehiclePartByCode = () => {
		vehiclePartService.getVehiclePartByCode(productCode).then(res => {
			setProductName(res.productName || '');
			setBrandId(res.brandId || '');
			setModelId(res.modelId || '');
		});
	}

	const isDisabled =
		!productCode ||
		!productName ||
		!brandId ||
		!modelId ||
		!categoryId ||
		!shelfId ||
		!quantityInStock ||
		!purchasePrice;

	return (
		<Stack spacing={2}>
			<Stack>
				<Typography variant="subtitle2">Stok Kodu</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="fullName"
					placeholder="Stok Kodu"
					value={productCode}
					onChange={(e) => setProductCode(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<QrCode2OutlinedIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton disabled={!productCode} onClick={getVehiclePartByCode} >
									<AutoAwesomeIcon />
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Stok Adı</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="productName"
					placeholder="Stok Adı"
					value={productName}
					onChange={(e) => setProductName(e.target.value)}
				/>
			</Stack>

			<Stack flexDirection="row" gap="10px">
				<Stack flex={1}>
					<Typography variant="subtitle2">Marka</Typography>
					<Select
						value={brandId}
						onChange={(e) => setBrandId(Number(e.target.value))}
					>
						{
							brands.map((brand) => (
								<MenuItem value={brand.id}>{brand.name}</MenuItem>
							))
						}
					</Select>
				</Stack>

				<Stack flex={1}>
					<Typography variant="subtitle2">Model</Typography>
					<Select
						value={modelId}
						onChange={(e) => setModelId(Number(e.target.value))}
					>
						{
							models.map((model) => (
								<MenuItem value={model.id}>{model.name}</MenuItem>
							))
						}
					</Select>
				</Stack>
			</Stack>

			<Stack flexDirection="row" gap="10px">
				<Stack flex={1}>
					<Typography variant="subtitle2">Kategori</Typography>
					<Select
						value={categoryId}
						onChange={(e) => setCategoryId(Number(e.target.value))}
					>
						{
							categories.map((category) => (
								<MenuItem value={category.id}>{category.name}</MenuItem>
							))
						}
					</Select>
				</Stack>

				<Stack flex={1}>
					<Typography variant="subtitle2">Raf</Typography>
					<Select
						value={shelfId}
						onChange={(e) => setShelfId(Number(e.target.value))}
					>
						{
							shelfs.map((shelf) => (
								<MenuItem value={shelf.id}>{shelf.name}</MenuItem>
							))
						}
					</Select>
				</Stack>
			</Stack>


			<Stack>
				<Typography variant="subtitle2">Miktar</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="mileage"
					placeholder="Miktar"
					value={quantityInStock}
					type="number"
					onChange={(e) => setQuantityInStock(Number(e.target.value))}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Birim Fiyat</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="mileage"
					placeholder="Birim Fiyat"
					value={purchasePrice}
					type="number"
					onChange={(e) => setPurchasePrice(Number(e.target.value))}
				/>
			</Stack>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button variant="outlined" color="primary" sx={{ flex: 1 }} onClick={handleCloseDialog}>
					İptal
				</Button>
				<Button disabled={isDisabled} variant="contained" color="primary" sx={{ flex: 1 }} onClick={handleClickSave}>
					Kaydet
				</Button>
			</Stack>
		</Stack>
	);
}

export default AddNewStock;