import { AxiosInstance } from "axios"
import { Brand } from "../models/brand"
import { createBrandURL, deleteBrandURL, getAllBrandsURL } from "../global"
import { NewBrand } from "../models/new-brand"

export interface BrandServiceType {
	getAllBrands: () => Promise<Brand[]>
	createBrand: (payload: NewBrand) => Promise<void>
	deleteBrand: (id: number) => Promise<void>
}

export const BrandService = ({ api }: { api: AxiosInstance }): BrandServiceType => {

	const getAllBrands = () => {
		return api.get<void, Brand[]>(getAllBrandsURL);
	}

	const createBrand = (payload: NewBrand) => {
		return api.post<NewBrand, void>(createBrandURL, payload);
	}

	const deleteBrand = (id: number) => {
		return api.delete<void, void>(`${deleteBrandURL}/${id}`);
	}

	return {
		getAllBrands,
		createBrand,
		deleteBrand
	}

}