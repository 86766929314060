import CustomerManagement from "./pages/customer-management";
import ServiceForms from "./pages/service-forms";
import StockManagement from "./pages/stock-management";
import UserManagement from "./pages/user-management";
import DashboardIcon from './assets/icons/dashboard.svg';
import UserManagementIcon from './assets/icons/user-management.svg';
import CustomerManagementIcon from './assets/icons/customer-management.svg';
import ServiceFormsIcon from './assets/icons/service-forms.svg';
import StockManagementIcon from './assets/icons/stock-management.svg';
import StockDefinitionsIcon from './assets/icons/inbox.svg';
import StockDefinitions from "./pages/stock-definitions";
import { Role } from "./enums/role";
import ServiceFormRecords from "./pages/service-form-records";

export const pages = [
	{
		title: "Kullanıcı Yönetimi",
		path: "kullanici-yonetimi",
		element: <UserManagement />,
		icon: UserManagementIcon,
		roles: [Role.ADMIN]
	},
	{
		title: "Müşteri Yönetimi",
		path: "musteri-yonetimi",
		element: <CustomerManagement />,
		icon: CustomerManagementIcon,
		roles: [Role.ADMIN, Role.USER]
	},
	{
		title: "Servis Formları",
		path: "servis-formlari",
		element: <ServiceForms />,
		icon: ServiceFormsIcon,
		roles: [Role.ADMIN, Role.USER]
	},
	{
		title: "Servis Form Kayıtları",
		path: "servis-form-kayitlari",
		element: <ServiceFormRecords />,
		icon: ServiceFormsIcon,
		roles: [Role.ADMIN, Role.USER]
	},
	{
		title: "Stok Yönetimi",
		path: "stok-yonetimi",
		element: <StockManagement />,
		icon: StockManagementIcon,
		roles: [Role.ADMIN, Role.USER]
	},
	{
		title: "Stok Tanımları",
		path: "stok-tanimlari",
		element: <StockDefinitions />,
		icon: StockDefinitionsIcon,
		roles: [Role.ADMIN]
	}
];

export const apiBaseURL = "https://admin.yasarmoto.com/api";

const customerBaseURL = "/customer";
export const getAllCustomersURL = customerBaseURL;
export const getNamesAllCustomerssURL = customerBaseURL + "/getNames";
export const createCustomerURL = customerBaseURL;
export const updateCustomerURL = customerBaseURL;
export const deleteCustomerURL = customerBaseURL;


const vehicleBaseURL = "/vehicle";
export const getAllVehiclesURL = vehicleBaseURL;
export const getNamesAllVehiclesURL = vehicleBaseURL + "/getNames";
export const createVehicleURL = vehicleBaseURL;
export const deleteVehicleURL = vehicleBaseURL

const serviceFormBaseURL = "/serviceForm";
export const getAllServiceFormRecordsURL = serviceFormBaseURL + "/getAllRecords";
export const getAllServiceFormsURL = serviceFormBaseURL;
export const createServiceFormURL = serviceFormBaseURL;
export const updateStatusServiceFormURL = serviceFormBaseURL;
export const updateServiceFormURL = serviceFormBaseURL + "/update";
export const deleteServiceFormURL = serviceFormBaseURL;

const brandBaseURL = "/brand"
export const getAllBrandsURL = brandBaseURL;
export const createBrandURL = brandBaseURL;
export const deleteBrandURL = brandBaseURL;

const categoryBaseURL = "/category"
export const getAllCategoriesURL = categoryBaseURL;
export const createCategoryURL = categoryBaseURL;
export const deleteCategoryURL = categoryBaseURL;

const shelfBaseURL = "/shelf"
export const getAllShelfsURL = shelfBaseURL;
export const createShelfURL = shelfBaseURL;
export const deleteShelfURL = shelfBaseURL;

const modelBaseURL = "/model"
export const getAllModelsURL = modelBaseURL;
export const createModelURL = modelBaseURL;
export const deleteModelURL = modelBaseURL;

const stockBaseURL = "/stock";
export const createStockURL = stockBaseURL;
export const deleteStockURL = stockBaseURL;
export const getFilteredStocksURL = stockBaseURL + "/getAllStocks";
export const updateStockURL = stockBaseURL;


const userBaseURL = "/user";
export const getAllUsersURL = userBaseURL;
export const createUserURL = userBaseURL;
export const deleteUserURL = userBaseURL;

const roleBaseURL = "/role";
export const getAllRolesURL = roleBaseURL;

const authBaseURL = "/auth";
export const loginURL = authBaseURL + "/login";
export const logoutURL = authBaseURL + "/logout";

const vehiclePartBaseURL = "/vehiclePart";
export const getAllVehiclePartsURL = vehiclePartBaseURL + "/getAllVehicleParts";
export const getVehiclePartByCodeURL = vehiclePartBaseURL;
export const getAlternativeModelsByCodeURL = vehiclePartBaseURL + "/alternativeModels";

const branchBaseURL = "/branch";
export const getAllBranchsURL = branchBaseURL;