import { IconButton, Stack, Typography } from "@mui/material";
import PageHeader from "../components/shared/page-header";
import { useEffect, useState } from "react";
import CustomAddButton from "../components/shared/custom-add-button";
import CustomSearchTextField from "../components/shared/custom-search-textfield";
import { useService } from "../provider/service-provider";
import { Customer } from "../models/customer.model";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "../components/shared/custom-data-grid";
import { useDialog } from "../provider/dialog-provider";
import AddNewCustomer from "../components/customer-management/add-new-customer";
import { NewCustomer } from "../models/new-customer.model";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { UpdatedCustomer } from "../models/update-customer.model";
import UpdateCustomer from "../components/customer-management/update-customer";
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import Vehicles from "../components/customer-management/vehicles";
import CustomConfirmation from "../components/shared/custom-confimation";
import { Role } from "../enums/role";

const CustomerManagement = () => {

	const { customerService } = useService();
	const { handleOpenDialog, handleCloseDialog } = useDialog();

	const [searchValue, setSearchValue] = useState("");
	const [customers, setCustomers] = useState<Customer[]>([]);
	const [filteredCustomer, setFilteredCustomers] = useState<Customer[]>([]);

	const getAllCustomers = () => {
		customerService.getAllCustomers().then((res) => {
			setCustomers(res);
			setFilteredCustomers(res);
			setSearchValue("");
		});
	}

	useEffect(getAllCustomers, []);

	useEffect(() => {
		setFilteredCustomers([...customers.filter(customer => customer.fullName.includes(searchValue))])
	}, [searchValue]);

	const columns: GridColDef[] = [
		{ field: 'fullName', headerName: "Müşteri Ad/Ünvan", width: 200 },
		{ field: 'phoneNumber', headerName: "Telefon", width: 200 },
		{ field: 'email', headerName: "E Mail", width: 200 },
		{ field: 'address', headerName: "Adres", width: 200 },
		{
			field: "actions", headerName: "", flex: 1,
			renderCell: (params) => (
				<Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" height="100%">
					<IconButton
						color="primary"
						onClick={() => openVehicleDialog(params.row)}
					>
						<TwoWheelerIcon />
					</IconButton>
					<IconButton
						color="primary"
						onClick={() => openEditCustomerDialog(params.row)}
					>
						<EditIcon />
					</IconButton>
					{
						localStorage.getItem("role") == Role.ADMIN && (
							<IconButton
								color="error"
								onClick={() => openConfirmationDialog(params.row.id)}
							>
								<DeleteOutlineOutlinedIcon />
							</IconButton>
						)
					}
				</Stack>
			),
		}
	];

	const openNewCustomerDialog = () => {
		handleOpenDialog(<AddNewCustomer handleSave={createCustomer} />, {
			headerTitle: "Yeni Müşteri Ekle",
			haveCloseIcon: true
		});
	}

	const openEditCustomerDialog = (customer: Customer) => {
		handleOpenDialog(<UpdateCustomer handleSave={updateCustomer} customer={customer} />, {
			headerTitle: "Müşteri Düzenle",
			haveCloseIcon: true
		});
	}

	const openVehicleDialog = (customer: Customer) => {
		handleOpenDialog(<Vehicles customerId={customer.id} />, {
			headerTitle: "Araçlar",
			haveCloseIcon: true
		});
	}

	const createCustomer = (newCustomer: NewCustomer) => {
		customerService.createCustomer(newCustomer).then(() => {
			handleCloseDialog();
			getAllCustomers();
		});
	}

	const updateCustomer = (updatedCustomer: UpdatedCustomer) => {
		customerService.updateCustomer(updatedCustomer).then(() => {
			handleCloseDialog();
			getAllCustomers();
		});
	}

	const openConfirmationDialog = (id: number) => {
		handleOpenDialog(<CustomConfirmation handleClickConfirm={() => deleteCustomer(id)} subtitle="Bu müşteriye bağlı olan araçlar ve servis formları da silinecektir." />, {
			headerTitle: "Emin Misiniz?",
			haveCloseIcon: true
		});
	}

	const deleteCustomer = (id: number) => {
		customerService.deleteCustomer(id).then(() => {
			handleCloseDialog();
			getAllCustomers();
		})
	}

	return (
		<Stack>
			<PageHeader title="Müşteri Yönetimi" />
			<Stack p="10px">
				<Stack p="30px" gap="30px" borderRadius="20px" border="1px #E8EAEC solid">
					<Typography fontSize="20px" fontWeight={600}>Tüm Müşteriler</Typography>
					<Stack flexDirection="row" justifyContent="space-between">
						<CustomSearchTextField searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Müşteri Ara" />
						<CustomAddButton title="Yeni Müşteri Ekle" onClick={openNewCustomerDialog} />
					</Stack>
					<CustomDataGrid
						columns={columns}
						rows={filteredCustomer}
						disableColumnMenu
						disableColumnResize
						disableRowSelectionOnClick
						initialState={{
							pagination: {
								paginationModel: { pageSize: 10 },
							},
						}}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default CustomerManagement;