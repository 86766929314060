import { AxiosInstance } from "axios";
import { createStockURL, deleteStockURL, getFilteredStocksURL, updateStockURL } from "../global";
import { UpdatedStock } from "../models/updated-stock.model";
import { NewStock } from "../models/new-stock.model";
import { Stock } from "../models/stock.model";
import { StockFilter } from "../models/stock-filter.model";
import { StockPage } from "../models/stock-page.model";

export interface StockServiceType {
	getAllStocks: (filter: StockFilter ) => Promise<StockPage>
	createStock: (payload: NewStock) => Promise<void>
	updateStock: (payload: UpdatedStock) => Promise<void>
	deleteStock: (id: number) => Promise<void>
}

export const StockService = ({ api }: { api: AxiosInstance }): StockServiceType => {

	const getAllStocks = (filter: StockFilter) => {
		return api.post<void, StockPage>(getFilteredStocksURL, filter);
	}

	const createStock = (payload: NewStock) => {
		return api.post<NewStock, void>(createStockURL, payload);
	}

	const updateStock = (payload: UpdatedStock) => {
		return api.put<UpdatedStock, void>(updateStockURL, payload);
	}

	const deleteStock = (id: number) => {
		return api.delete<void, void>(`${deleteStockURL}/${id}`);
	}

	return {
		getAllStocks,
		createStock,
		updateStock,
		deleteStock
	}

}