import { FC, useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useDialog } from "../../provider/dialog-provider";
import { ServiceForm } from "../../models/service-form.model";
import ServiceFormPrintDetail from "./service-form-print-detail";
import { UpdateServiceForm } from "../../models/update-service-form.model";

interface UpdateServiceFormProps {
	serviceForm: ServiceForm
	handleSave: (updatedServiceForm: UpdateServiceForm) => void
}
const UpdateStatusServiceForm: FC<UpdateServiceFormProps> = ({ serviceForm, handleSave }) => {

	const { handleOpenDialog, handleCloseDialog } = useDialog();

	const [laborCost, setLaborCost] = useState<number>(serviceForm.laborCost);
	const [partsCost, setPartsCost] = useState<number>(serviceForm.partsCost);
	const [notes, setNotes] = useState<string>(serviceForm.notes);

	const handleClickSave = () => {
		handleSave({
			id: serviceForm.id,
			laborCost,
			partsCost,
			notes
		});
	};

	const isDisabled =
		!laborCost ||
		!partsCost;

	const handleOpenPrintDialog = () => {
		handleOpenDialog(<ServiceFormPrintDetail serviceForm={serviceForm} />)
	}

	return (
		<Stack spacing={2}>

			<Stack>
				<Typography variant="subtitle2">Müşteri</Typography>
				<TextField value={serviceForm.customer.fullName} disabled />
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Plaka</Typography>
				<TextField value={serviceForm.vehicle.licensePlate} disabled />
			</Stack>

			{
				serviceForm.chassisNumber ? (
					<Stack>
						<Typography variant="subtitle2">Şase Numarası</Typography>
						<TextField value={serviceForm.chassisNumber} disabled />
					</Stack>
				) : null
			}

			<Stack>
				<Typography variant="subtitle2">Tarih</Typography>
				<TextField value={dayjs(serviceForm.serviceDate).format("DD.MM.YYYY HH:mm")} disabled />
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Kilometre</Typography>
				<TextField value={serviceForm.mileage} disabled />
			</Stack>

			<Stack flexDirection="row" gap="10px">
				<Stack flex={1}>
					<Typography variant="subtitle2">İşçilik Fiyatı</Typography>
					<TextField
						fullWidth
						variant="outlined"
						name="laborCost"
						placeholder="İşçilik Fiyatı"
						value={laborCost}
						type="number"
						onChange={(e) => setLaborCost(Number(e.target.value))}
					/>
				</Stack>

				<Stack flex={1}>
					<Typography variant="subtitle2">Parça Fiyatı</Typography>
					<TextField
						fullWidth
						variant="outlined"
						name="partsCost"
						placeholder="Parça Fiyatı"
						value={partsCost}
						type="number"
						onChange={(e) => setPartsCost(Number(e.target.value))}
					/>
				</Stack>
			</Stack>

			<Stack >
				<Typography variant="subtitle2">Toplam Fiyat</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="partsCost"
					placeholder="Parça Fiyatı"
					disabled
					value={(Number(laborCost) + Number(partsCost))}
					type="number"
				/>
			</Stack>

			<Stack >
				<Typography variant="subtitle2">Müşteri Notları</Typography>
				<TextField 
				value={serviceForm.customerNotes || ''} 
				disabled
				multiline
				rows={(serviceForm.customerNotes?.split('\n').length || 1)}
				/>
			</Stack>

			<Stack >
				<Typography variant="subtitle2">Not</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="partsCost"
					placeholder="Not"
					multiline
					rows={(notes?.split('\n').length || 1)}  
					value={notes || ''}
					onChange={(e) => setNotes(e.target.value)}
				/>
			</Stack>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button sx={{ flex: 1 }} variant="outlined" onClick={handleCloseDialog}>
					İptal
				</Button>
				<Button disabled={isDisabled} sx={{ flex: 1 }} variant="contained" onClick={handleClickSave}>
					Kaydet
				</Button>
				<Button sx={{ flex: 1 }} variant="contained" onClick={handleOpenPrintDialog}>
					Yazdır
				</Button>
			</Stack>

		</Stack>
	);
}

export default UpdateStatusServiceForm;