
import { Dispatch, FC, SetStateAction } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DroppableProvided, OnDragEndResponder, ResponderProvided } from 'react-beautiful-dnd';
import { ServiceFormMap } from '../../service/service-form-service';
import { getServiceStatucIcon, getServiceStatusPriority, ServiceStatus } from '../../enums/service-status.enum';
import { Stack, Typography } from '@mui/material';
import DraggablePlate from './draggable-plate';
import { useService } from '../../provider/service-provider';

interface DragDropListProps {
	data: ServiceFormMap
	setData: Dispatch<SetStateAction<ServiceFormMap>>
	deleteServiceForm: (id: number) => void
	getAllServiceForms: () => void
}
const DragDropList: FC<DragDropListProps> = ({ data, setData, deleteServiceForm, getAllServiceForms }) => {

	const { serviceFormService } = useService();

	const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
		const { source, destination } = result;

		if (!destination) return; // Dropped outside a droppable area

		const sourceDroppableId = result.source.droppableId as keyof typeof ServiceStatus;
		const destinationDroppableId = result.destination?.droppableId as keyof typeof ServiceStatus;

		if (sourceDroppableId === destinationDroppableId) {
			// Reorder within the same list
			const list = data[sourceDroppableId];
			const [movedItem] = list.splice(source.index, 1);
			list.splice(destination.index, 0, movedItem);

			setData((prevData) => ({
				...prevData,
				[sourceDroppableId]: list
			}));

		} else {
			// Move between lists
			const sourceList = data[sourceDroppableId];
			const destinationList = data[destinationDroppableId];

			const [movedItem] = sourceList.splice(source.index, 1);
			destinationList.splice(destination.index, 0, movedItem);

			setData((prevData) => ({
				...prevData,
				[source.droppableId]: sourceList,
				[destinationDroppableId]: destinationList
			}));

			serviceFormService.updateStatusServiceForm({id: movedItem.id, serviceStatus: destinationDroppableId}).then();
		}
	};
	
	return (

		<DragDropContext onDragEnd={onDragEnd}>
			<Stack flexDirection="row" gap="30px" >

				{Object.entries(data)
					.sort(([a,], [b,]) => getServiceStatusPriority(a as keyof typeof ServiceStatus) - getServiceStatusPriority(b as keyof typeof ServiceStatus))
					.map(([serviceStatus, list]) => (

						<Droppable droppableId={serviceStatus} key={serviceStatus} >

							{(provided) => (

								<Stack {...provided.droppableProps} ref={provided.innerRef} sx={{ height:"570px", overflowY: 'auto', padding: "20px", backgroundColor: "#E8EBED", borderRadius: "15px", width: 310, minHeight: 500, scrollbarWidth: 'thin', '&::-webkit-scrollbar': { display: 'none'} }}>

									<Stack flexDirection="row" justifyContent="center" alignItems="center" gap="10px">
										{getServiceStatucIcon(serviceStatus as keyof typeof ServiceStatus)}
										<Typography fontWeight={700} fontSize={13}>{ServiceStatus[serviceStatus as keyof typeof ServiceStatus]}</Typography>
									</Stack>

									{list.map((item, index) => (

										<Draggable key={item.id} draggableId={item.id.toString()} index={index}>
											{(provided) => (
												<DraggablePlate provided={provided} serviceForm={item} serviceStatus={serviceStatus} deleteServiceForm={deleteServiceForm} getAllServiceForms={getAllServiceForms} />
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</Stack>
							)}
						</Droppable>
					))}
			</Stack>
		</DragDropContext>
	);

}

export default DragDropList;