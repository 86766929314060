import { Stack, Typography } from "@mui/material";
import PageHeader from "../components/shared/page-header";
import { ServiceStatus } from "../enums/service-status.enum";
import { useEffect, useState } from "react";
import { useService } from "../provider/service-provider";
import { ServiceFormMap } from "../service/service-form-service";
import DragDropList from "../components/service-management/drap-drop-lists";
import CustomAddButton from "../components/shared/custom-add-button";
import { useDialog } from "../provider/dialog-provider";
import AddNewServiceForm from "../components/service-management/add-new-service-form";
import { NewServiceForm } from "../models/new-service-form.model";
import CustomSearchTextField from "../components/shared/custom-search-textfield";
import { ServiceForm } from "../models/service-form.model";
import CustomSwitch from "../components/shared/custom-switch";

const ServiceForms = () => {

	const { serviceFormService } = useService();
	const { handleOpenDialog, handleCloseDialog } = useDialog();

	const defaultData: ServiceFormMap = {
		AWAITING_SHIPMENT: [],
		DELIVERED: [],
		IN_SERVICE: [],
		READY: []
	};

	const [data, setData] = useState(defaultData);
	const [filteredData, setFilteredData] = useState(defaultData);
	const [searchValue, setSearchValue] = useState("");

	const [warrantOpen, setWarrantOpen] = useState(false);

	useEffect(() => {

		const filterServiceForms = (forms: ServiceForm[]) =>
			forms.filter(form =>
				(form.vehicle.licensePlate.toLowerCase().includes(searchValue.toLowerCase()) ||
					form.customer.fullName.toLowerCase().includes(searchValue.toLowerCase()) ||
					form.vehicle.brand.toLowerCase().includes(searchValue.toLowerCase()) ||
					form.vehicle.model.toLowerCase().includes(searchValue.toLowerCase())) &&
					warrantOpen ? form.chassisNumber : true
			);

		setFilteredData({
			AWAITING_SHIPMENT: filterServiceForms(data.AWAITING_SHIPMENT),
			DELIVERED: filterServiceForms(data.DELIVERED),
			IN_SERVICE: filterServiceForms(data.IN_SERVICE),
			READY: filterServiceForms(data.READY)
		})
	}, [searchValue, warrantOpen]);

	const getAllServiceForms = () => {
		serviceFormService.getAllServiceForms().then((res) => {
			setData({ AWAITING_SHIPMENT: res.AWAITING_SHIPMENT || [], DELIVERED: res.DELIVERED || [], IN_SERVICE: res.IN_SERVICE || [], READY: res.READY || [] });
			setFilteredData({ AWAITING_SHIPMENT: res.AWAITING_SHIPMENT || [], DELIVERED: res.DELIVERED || [], IN_SERVICE: res.IN_SERVICE || [], READY: res.READY || [] });
			setSearchValue("");
			setWarrantOpen(false);
		})
	}

	useEffect(getAllServiceForms, []);

	const openNewServiceFormDialog = () => {
		handleOpenDialog(<AddNewServiceForm handleSave={createServiceForm} />, {
			headerTitle: "Yeni Servis Kaydı Ekle",
			haveCloseIcon: true
		});
	}

	const createServiceForm = (newServiceForm: NewServiceForm) => {
		serviceFormService.createServiceForm(newServiceForm).then(() => {
			handleCloseDialog();
			getAllServiceForms();
		})
	}

	const deleteServiceForm = (id: number) => {
		serviceFormService.deleteServiceForm(id).then(() => {
			handleCloseDialog();
			getAllServiceForms();
		})
	}

	return (
		<Stack height="100%">
			<PageHeader title="Servis Formları" />
			<Stack flex={1} py="20px" px="10px" gap="20px" justifyContent="center" >
				<Stack flexDirection="row" justifyContent="space-between">
					<CustomSearchTextField searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Ara" />
					<Stack flexDirection="row">
						<Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
							<Typography variant="subtitle2">Garanti Kapsamı</Typography>
							<CustomSwitch value={warrantOpen} onChange={(e, checked) => setWarrantOpen(checked)} />
						</Stack>
						<CustomAddButton title="Yeni Kayıt Ekle" onClick={openNewServiceFormDialog} />
					</Stack>
				</Stack>
				<Stack p="30px" gap="50px" flexDirection="row" borderRadius="20px" border="1px #E8EAEC solid" justifyContent="center" alignItems="center">
					<Stack>
						<Typography color="#444" variant="body1">Toplam Servis Kaydı</Typography>
						<Typography variant="h5" fontWeight={600}>{Object.entries(filteredData).reduce((sum, [key, item]) => sum + item.length, 0)}</Typography>
					</Stack>
					{
						Object.entries(filteredData).map(([key, value]) => (
							<Stack>
								<Typography color="#444" variant="body1">{ServiceStatus[key as keyof typeof ServiceStatus]}</Typography>
								<Typography variant="h5" fontWeight={600}>{value.length}</Typography>
							</Stack>
						))
					}
				</Stack>
				<Stack p="10px" flexDirection="row" borderRadius="20px" border="1px #E8EAEC solid" justifyContent="center">

					<DragDropList data={filteredData} setData={setFilteredData} deleteServiceForm={deleteServiceForm} getAllServiceForms={getAllServiceForms} />

				</Stack>
			</Stack>
		</Stack>
	);
};

export default ServiceForms;