import { FC, useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useDialog } from "../../provider/dialog-provider";
import { NewShelf } from "../../models/new-shelf";

interface AddNewShelfProps {
	handleSave: (shelf: NewShelf) => void
}
const AddNewShelf: FC<AddNewShelfProps> = ({ handleSave }) => {

	const { handleCloseDialog } = useDialog();

	const [shelfName, setShelfName] = useState("");

	const handleClickSave = () => {
		handleSave({ name: shelfName });
	};

	return (
		<Stack spacing={2}>
			<Stack>
				<Typography variant="subtitle2">Adı</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="fullName"
					placeholder="Adı"
					value={shelfName}
					onChange={(e) => setShelfName(e.target.value)}
				/>
			</Stack>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button variant="outlined" onClick={handleCloseDialog}>
					İptal
				</Button>
				<Button disabled={!shelfName} variant="contained" onClick={handleClickSave}>
					Kaydet
				</Button>
			</Stack>
		</Stack>
	);
}

export default AddNewShelf;