import { Box, InputAdornment, Skeleton, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { FC } from "react";

interface CustomSearchTextFieldProps {
	searchValue: string | null
	setSearchValue: (value: string) => void
	placeholder: string
}

const CustomSearchTextField: FC<CustomSearchTextFieldProps> = ({ searchValue, setSearchValue, placeholder }) => {

	return (
		<Box sx={{ width: "220px", height: "100%" }}>
			<TextField
				value={searchValue}
				onChange={(e) => setSearchValue(e.target.value)}
				label="Ara"
				placeholder={placeholder}
				type="search"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
		</Box>
	);
}

export default CustomSearchTextField;