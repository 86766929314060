import { AxiosInstance } from "axios";
import { createVehicleURL, deleteVehicleURL, getAllVehiclesURL, getNamesAllVehiclesURL } from "../global";
import { NewVehicle } from "../models/new-vehicle.model";
import { VehicleModel } from "../models/vehicle.model";
import { VehicleName } from "../models/vehicle-name.model";

export interface VehicleServiceType {
	getAllVehicles: (customerId: number) => Promise<VehicleModel[]>
	getNames: () => Promise<VehicleName[]>
	createVehicle: (payload: NewVehicle) => Promise<void>
	deleteVehicle: (id: number) => Promise<void>
	getNamesByCustomer: (customerId: number) => Promise<VehicleName[]>
}
export const VehicleService = ({ api }: { api: AxiosInstance }): VehicleServiceType => {

	const getAllVehicles = (customerId: number) => {
		return api.get<void, VehicleModel[]>(`${getAllVehiclesURL}/${customerId}`);
	}

	const getNames = () => {
		return api.get<void, VehicleName[]>(getNamesAllVehiclesURL);
	}

	const createVehicle = (payload: NewVehicle) => {
		return api.post<NewVehicle, void>(createVehicleURL, payload);
	}

	const deleteVehicle = (id: number) => {
		return api.delete<void, void>(`${deleteVehicleURL}/${id}`);
	}
	
	const getNamesByCustomer = (customerId: number) => {
		return api.get<void, VehicleName[]>(`${getNamesAllVehiclesURL}/${customerId}`);
	}

	return {
		getAllVehicles,
		getNames,
		createVehicle,
		deleteVehicle,
		getNamesByCustomer
	};

}