import { Button, Stack, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useDialog } from "../../provider/dialog-provider";
import { useService } from "../../provider/service-provider";
import { NewCustomer } from "../../models/new-customer.model";
interface AddNewCustomerProps {
	handleSave: (newCustomer: NewCustomer) => void
}
const AddNewCustomer: FC<AddNewCustomerProps> = ({handleSave}) => {

	const { handleCloseDialog } = useDialog();

	const [fullName, setFullName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [email, setEmail] = useState('');
	const [address, setAddress] = useState('');

	const handleClickSave = () => {
		handleSave({ fullName, phoneNumber, email, address });
	};

	const isDisabled = !fullName || !phoneNumber || !email || !address;

	return (
		<Stack spacing={2}>
			<Stack>
				<Typography variant="subtitle2">Müşteri Ad/Ünvan</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="fullName"
					placeholder="Müşteri Ad/Ünvan"
					value={fullName}
					onChange={(e) => setFullName(e.target.value)}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Telefon</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="phoneNumber"
					placeholder="Telefon"
					value={phoneNumber}
					onChange={(e) => setPhoneNumber(e.target.value)}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">E Mail</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="email"
					placeholder="E Mail"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Adres</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="address"
					placeholder="Adres"
					value={address}
					onChange={(e) => setAddress(e.target.value)}
				/>
			</Stack>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button sx={{ flex: 1 }} variant="outlined" onClick={handleCloseDialog}>
					İptal
				</Button>
				<Button disabled={isDisabled} sx={{ flex: 1 }} variant="contained" onClick={handleClickSave}>
					Kaydet
				</Button>
			</Stack>
		</Stack>
	);
}

export default AddNewCustomer;