import { Box, Button, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FC } from "react";

interface CustomAddButtonProps {
	title: string
	onClick: () => void
}

const CustomAddButton: FC<CustomAddButtonProps> = ({ title, onClick }) => {

	return (
		<Box sx={{ width: "220px", height: "100%", display: "flex", justifyContent: "flex-end" }}>
			<Button
				variant="contained"
				color="success"
				startIcon={<AddCircleOutlineIcon />}
				sx={{ paddingX: "25px", borderRadius: "8px"}}
				onClick={onClick}
			>
				<Typography fontSize="14px">
					{title}
				</Typography>
			</Button>
		</Box>
	);
}

export default CustomAddButton;