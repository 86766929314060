import { FC, useEffect, useState } from "react";
import { NewServiceForm } from "../../models/new-service-form.model";
import { Button, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useService } from "../../provider/service-provider";
import { CustomerName } from "../../models/customer-name.model";
import { VehicleName } from "../../models/vehicle-name.model";
import { useDialog } from "../../provider/dialog-provider";
import CustomSwitch from "../shared/custom-switch";


interface AddNewServiceFormProps {
	handleSave: (newServiceForm: NewServiceForm) => void
}
const AddNewServiceForm: FC<AddNewServiceFormProps> = ({ handleSave }) => {

	const { handleCloseDialog } = useDialog();
	const { customerService, vehicleService } = useService();

	const [customers, setCustomers] = useState<CustomerName[]>([]);
	const [vehicles, setVehicles] = useState<VehicleName[]>([]);

	const [customerId, setCustomerId] = useState<number | ''>('');
	const [vehicleId, setVehicleId] = useState<number | ''>('');
	const [serviceDate, setServiceDate] = useState<Dayjs | null>(dayjs());
	const [mileage, setMileage] = useState<number | ''>('');
	const [customerNotes, setCustomerNotes] = useState<string>('');
	const [chassisNumber, setChassisNumber] = useState('');

	const [warrantOpen, setWarrantOpen] = useState(false);

	const handleClickSave = () => {
		handleSave({
			customerId: Number(customerId),
			vehicleId: Number(vehicleId),
			serviceDate: (serviceDate || dayjs()).toDate(),
			mileage: Number(mileage),
			customerNotes,
			chassisNumber
		});
	};

	useEffect(() => {
		customerService.getNames().then(setCustomers)
	}, []);

	useEffect(() => {
		if (customerId) {
			vehicleService.getNamesByCustomer(customerId).then(setVehicles);
		} else {
			setVehicles([]); // Müşteri seçilmediğinde plakaları sıfırla
		}
	}, [customerId]);

	const isDisabled =
		!customerId ||
		!vehicleId ||
		!serviceDate ||
		!mileage ||
		!customerNotes;


	return (
		<Stack spacing={2}>

			<Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
				<Typography variant="subtitle2">Garanti Kapsamı</Typography>
				<CustomSwitch value={warrantOpen} onChange={(e, checked) => setWarrantOpen(checked)} />
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Müşteri</Typography>
				<Select
					value={customerId}
					onChange={(e) => setCustomerId(Number(e.target.value))}
				>
					{
						customers.map((customer) => (
							<MenuItem value={customer.id}>{customer.fullName}</MenuItem>
						))
					}
				</Select>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Plaka</Typography>
				<Select
					value={vehicleId}
					onChange={(e) => setVehicleId(Number(e.target.value))}
					disabled={!vehicles.length && Boolean(customerId)} // Araç yoksa devre dışı
				>
					{customerId ? (
						vehicles.length > 0 ? (
							vehicles.map((vehicle) => (
								<MenuItem key={vehicle.id} value={vehicle.id}>{vehicle.licensePlate}</MenuItem>
							))
						) : (
							<MenuItem disabled>Müşteriye ait plaka bulunamadı</MenuItem>
						)
					) : (
						<MenuItem disabled>Önce müşteri seçin</MenuItem>
					)}
				</Select>
				{customerId && !vehicles.length && (
					<Typography variant="body2" color="error">
						Bu müşteriye ait araç bulunamadı.
					</Typography>
				)}
			</Stack>

			{
				warrantOpen ? (
					<Stack>
						<Typography variant="subtitle2">Şase Numarası</Typography>
						<TextField
							fullWidth
							variant="outlined"
							name="chassisNumber"
							placeholder="Şase Numarası"
							value={chassisNumber}
							onChange={(e) => setChassisNumber(e.target.value)}
						/>
					</Stack>
				) : null
			}

			<Stack>
				<Typography variant="subtitle2">Tarih</Typography>
				<DatePicker
					format='DD.MM.YYYY'
					value={serviceDate}
					onChange={(newValue) => setServiceDate(newValue)}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Kilometre</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="mileage"
					placeholder="Kilometre"
					value={mileage}
					type="number"
					onChange={(e) => setMileage(Number(e.target.value))}
				/>
			</Stack>

			<Stack >
				<Typography variant="subtitle2">Müşteri Notları</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="customerNotes"
					placeholder="Müşteri Notları"
					multiline
					value={customerNotes}
					onChange={(e) => setCustomerNotes(e.target.value)}
				/>
			</Stack>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button sx={{ flex: 1 }} variant="outlined" onClick={handleCloseDialog}>
					İptal
				</Button>
				<Button disabled={isDisabled} sx={{ flex: 1 }} variant="contained" onClick={handleClickSave}>
					Kaydet
				</Button>
			</Stack>

		</Stack>
	);
}

export default AddNewServiceForm;