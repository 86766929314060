import { IconButton, Stack, Typography } from "@mui/material";
import PageHeader from "../components/shared/page-header";
import CustomSearchTextField from "../components/shared/custom-search-textfield";
import CustomDataGrid from "../components/shared/custom-data-grid";
import { useEffect, useState } from "react";
import { GridColDef, GridValueGetter } from "@mui/x-data-grid";
import InfoIcon from '@mui/icons-material/Info';
import { useDialog } from "../provider/dialog-provider";
import ServiceFormDetail from "../components/service-management/service-form-detail";
import { ServiceForm } from "../models/service-form.model";
import { useService } from "../provider/service-provider";
import dayjs from "dayjs";
import { getServiceStatusColor, ServiceStatus } from "../enums/service-status.enum";

const ServiceFormRecords = () => {

    const { serviceFormService } = useService();
    const { handleOpenDialog, handleCloseDialog } = useDialog();

    const [searchValue, setSearchValue] = useState("");
    const [serviceForms, setServiceForms] = useState<ServiceForm[]>([]);
    const [filteredServiceForms, setFilteredServiceForms] = useState<ServiceForm[]>([]);

    const getAllServiceForms = () => {
		serviceFormService.getAllServiceFormRecords().then((res) => {
			setServiceForms(res);
            setFilteredServiceForms(res);
			setSearchValue("");
		})
	}

    useEffect(getAllServiceForms, []);

    useEffect(() => {
		setFilteredServiceForms([...serviceForms.filter(serviceForm => 
            serviceForm.customer.fullName.toLowerCase().includes(searchValue.toLowerCase()) || 
            serviceForm.vehicle.licensePlate.toLowerCase().includes(searchValue.toLowerCase())
        )])
	}, [searchValue]);

    const openDetailDialog = (serviceForm: ServiceForm) => {
        handleOpenDialog(<ServiceFormDetail serviceForm={serviceForm} />, {
			headerTitle: "Servis Formu Detayları",
			haveCloseIcon: true
		});
    }

    const columns: GridColDef[] = [
		{ field: 'serviceStatus', headerName: "Durum", flex: 0.8, renderCell: (params) => (
			<Stack height="100%" justifyContent="center">
				<Typography color={getServiceStatusColor(params.row.serviceStatus)}>{ServiceStatus[params.row.serviceStatus as keyof typeof ServiceStatus]}</Typography>
			</Stack>
		)},
        { field: 'fullName', headerName: "Müşter Ad/Ünvan", flex: 1, renderCell: (params) => (
			<>{params.row.customer.fullName}</>
		)},
		{ field: 'licensePlate', headerName: "Plaka", flex: 1, renderCell: (params) => (
			<>{params.row.vehicle.licensePlate}</>
		)},
		{ field: 'serviceDate', headerName: "Tarih", flex: 1, renderCell: (params) => (
			<>{dayjs(params.row.serviceDate).format("DD.MM.YYYY HH:mm")}</>
		) },
		{ field: 'mileage', headerName: "Kilometre", flex: 1 },
        { field: 'laborCost', headerName: "Parça Fiyatı", flex: 1 },
        { field: 'partsCost', headerName: "İşçilik Fiyatı", flex: 1 },
		{ field: 'chassisNumber', headerName: "Garanti Kapsamı", flex: 1, renderCell: (params) => (
			<>{params.row.chassisNumber ? "Evet" : "Hayır"}</>
		) },
		{
			field: "actions", headerName: "", flex: 1,
			renderCell: (params) => (
				<Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" height="100%">
					<IconButton
						color="primary"
						onClick={() => openDetailDialog(params.row)}
					>
						<InfoIcon />
					</IconButton>
				</Stack>
			),
        }
	];


    return (
		<Stack>
			<PageHeader title="Servis Form Kayıtları" />
			<Stack p="10px">
				<Stack p="30px" gap="30px" borderRadius="20px" border="1px #E8EAEC solid">
					<Typography fontSize="20px" fontWeight={600}>Tüm Servis Formu Kayıtları</Typography>
					<Stack flexDirection="row" justifyContent="space-between">
						<CustomSearchTextField searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Servis Formu Ara" />
					</Stack>
					<CustomDataGrid
						columns={columns}
						rows={filteredServiceForms}
						disableColumnMenu
						disableColumnResize
						disableRowSelectionOnClick
						initialState={{
							pagination: {
								paginationModel: { pageSize: 10 },
							},
						}}
					/>
				</Stack>
			</Stack>
		</Stack>
	);

}

export default ServiceFormRecords;