import { FC, useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { NewBrand } from "../../models/new-brand";
import { useDialog } from "../../provider/dialog-provider";

interface AddNewBrandProps {
	handleSave: (brand: NewBrand) => void
}
const AddNewBrand: FC<AddNewBrandProps> = ({ handleSave }) => {

	const { handleCloseDialog } = useDialog();

	const [brandName, setBrandName] = useState("");

	const handleClickSave = () => {
		handleSave({ name: brandName });
	};

	return (
		<Stack spacing={2}>
			<Stack>
				<Typography variant="subtitle2">Adı</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="fullName"
					placeholder="Adı"
					value={brandName}
					onChange={(e) => setBrandName(e.target.value)}
				/>
			</Stack>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button variant="outlined" onClick={handleCloseDialog}>
					İptal
				</Button>
				<Button disabled={!brandName} variant="contained" onClick={handleClickSave}>
					Kaydet
				</Button>
			</Stack>
		</Stack>
	);
}

export default AddNewBrand;