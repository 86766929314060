import { AxiosInstance } from "axios";
import { getAllVehiclePartsURL, getAlternativeModelsByCodeURL, getVehiclePartByCodeURL } from "../global";
import { VehiclePart } from "../models/vehicle-part";
import { VehiclePartFilter } from "../models/vehicle-part-filter.model";
import { VehiclePartPage } from "../models/vehicle-part-page.model";

export interface VehiclePartServiceType {
	getAllVehicleParts: (filter: VehiclePartFilter) => Promise<VehiclePartPage>
	getVehiclePartByCode: (code: string) => Promise<VehiclePart>
	getAlternativeModelsByCode: (code: string) => Promise<string[]>
}
export const VehiclePartService = ({ api }: { api: AxiosInstance }): VehiclePartServiceType => {

	const getAllVehicleParts = (filter: VehiclePartFilter) => {
		return api.post<void, VehiclePartPage>(getAllVehiclePartsURL, filter);
	}

	const getVehiclePartByCode = (code: string) => {
		return api.get<void, VehiclePart>(`${getVehiclePartByCodeURL}/${code}`);
	}

	const getAlternativeModelsByCode = (code: string) => {
		return api.get<void, string[]>(`${getAlternativeModelsByCodeURL}/${code}`);
	}

	return {
		getAllVehicleParts,
		getVehiclePartByCode,
		getAlternativeModelsByCode
	};

}