import { Stack, Typography } from "@mui/material";
import PageHeader from "../components/shared/page-header";
import BrandTable from "../components/stock-definitions/brand-table";
import CategoryTable from "../components/stock-definitions/category-table";
import ModelTable from "../components/stock-definitions/model-table";
import ShelfTable from "../components/stock-definitions/shelf-table";
import CustomAddButton from "../components/shared/custom-add-button";

const StockDefinitions = () => {

	return (
		<Stack>
			<PageHeader title="Stok Tanımları" />
			<Stack p="10px" flexDirection="row" gap="10px">
				<Stack flex={1} p="30px 30px 0px 30px" gap="20px" borderRadius="20px" border="1px #E8EAEC solid">
					<BrandTable />
				</Stack>
				<Stack flex={1} p="30px 30px 0px 30px" gap="20px" borderRadius="20px" border="1px #E8EAEC solid">
					<ModelTable />
				</Stack>
				<Stack flex={1} p="30px 30px 0px 30px" gap="20px" borderRadius="20px" border="1px #E8EAEC solid">
					<CategoryTable />
				</Stack>
			</Stack>
			<Stack p="10px" flexDirection="row" gap="10px">
				<Stack p="30px 30px 0px 30px" gap="20px" borderRadius="20px" border="1px #E8EAEC solid">
					<ShelfTable />
				</Stack>
			</Stack>
		</Stack>
	);
}

export default StockDefinitions;