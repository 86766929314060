import { FC, useEffect, useState } from "react";
import { Button, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useDialog } from "../../provider/dialog-provider";
import { NewModel } from "../../models/new-model";
import { Brand } from "../../models/brand";
import { useService } from "../../provider/service-provider";

interface AddNewModelProps {
	handleSave: (model: NewModel) => void
}
const AddNewModel: FC<AddNewModelProps> = ({ handleSave }) => {

	const { handleCloseDialog } = useDialog();
	const { brandService } = useService();


	const [brands, setBrands] = useState<Brand[]>([]);

	useEffect(() => {
		brandService.getAllBrands().then(setBrands)
	}, []);

	const [modelName, setModelName] = useState("");
	const [brandId, setBrandId] = useState<number | string>('');

	const handleClickSave = () => {
		handleSave({ name: modelName, brandId: Number(brandId) });
	};

	return (
		<Stack spacing={2}>
			<Stack>
				<Typography variant="subtitle2">Adı</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="fullName"
					placeholder="Adı"
					value={modelName}
					onChange={(e) => setModelName(e.target.value)}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Marka</Typography>
				<Select
					value={brandId}
					onChange={(e) => setBrandId(Number(e.target.value))}
				>
					{
						brands.map((brand) => (
							<MenuItem value={brand.id}>{brand.name}</MenuItem>
						))
					}
				</Select>
			</Stack>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button variant="outlined" onClick={handleCloseDialog}>
					İptal
				</Button>
				<Button disabled={!modelName} variant="contained" onClick={handleClickSave}>
					Kaydet
				</Button>
			</Stack>
		</Stack>
	);
}

export default AddNewModel;