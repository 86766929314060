import { AxiosInstance } from "axios"
import { createCategoryURL, deleteCategoryURL, getAllCategoriesURL } from "../global"
import { Category } from "../models/category"
import { NewCategory } from "../models/new-category"

export interface CategoryServiceType {
	getAllCategories: () => Promise<Category[]>
	createCategory: (payload: NewCategory) => Promise<void>
	deleteCategory: (id: number) => Promise<void>
}

export const CategoryService = ({ api }: { api: AxiosInstance }): CategoryServiceType => {

	const getAllCategories = () => {
		return api.get<void, Category[]>(getAllCategoriesURL);
	}

	const createCategory = (payload: NewCategory) => {
		return api.post<NewCategory, void>(createCategoryURL, payload);
	}

	const deleteCategory = (id: number) => {
		return api.delete<void, void>(`${deleteCategoryURL}/${id}`);
	}

	return {
		getAllCategories,
		createCategory,
		deleteCategory
	}

}