import { AxiosInstance } from "axios"
import { createShelfURL, deleteShelfURL, getAllShelfsURL } from "../global"
import { Shelf } from "../models/shelf"
import { NewShelf } from "../models/new-shelf"

export interface ShelfServiceType {
	getAllShelfs: () => Promise<Shelf[]>
	createShelf: (payload: NewShelf) => Promise<void>
	deleteShelf: (id: number) => Promise<void>
}

export const ShelfService = ({ api }: { api: AxiosInstance }): ShelfServiceType => {

	const getAllShelfs = () => {
		return api.get<void, Shelf[]>(getAllShelfsURL);
	}

	const createShelf = (payload: NewShelf) => {
		return api.post<NewShelf, void>(createShelfURL, payload);
	}

	const deleteShelf = (id: number) => {
		return api.delete<void, void>(`${deleteShelfURL}/${id}`);
	}

	return {
		getAllShelfs,
		createShelf,
		deleteShelf
	}

}