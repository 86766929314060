import { Button, Stack, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useDialog } from "../../provider/dialog-provider";
import { UpdatedCustomer } from "../../models/update-customer.model";

interface UpdateCustomerProps {
	handleSave: (updatedCustomer: UpdatedCustomer) => void,
	customer: UpdatedCustomer
}
const UpdateCustomer: FC<UpdateCustomerProps> = ({handleSave, customer}) => {

	const { handleCloseDialog } = useDialog();

	const [updatedCustomer, setUpdatedCustomer] = useState(customer);

	const setFieldUpdatedCustomer = (fieldName: keyof UpdatedCustomer, value: string) => {
		setUpdatedCustomer(prev => ({...prev, [fieldName] : value}));
	}

	const handleClickSave = () => {
		handleSave(updatedCustomer);
	};

	const isDisabled = !updatedCustomer.fullName || !updatedCustomer.phoneNumber || !updatedCustomer.email || !updatedCustomer.address;

	return (
		<Stack spacing={2}>
			<Stack>
				<Typography variant="subtitle2">Müşteri Ad/Ünvan</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="fullName"
					placeholder="Müşteri Ad/Ünvan"
					value={updatedCustomer.fullName}
					onChange={(e) => setFieldUpdatedCustomer("fullName", e.target.value)}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Telefon</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="phoneNumber"
					placeholder="Telefon"
					value={updatedCustomer.phoneNumber}
					onChange={(e) => setFieldUpdatedCustomer("phoneNumber", e.target.value)}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">E Mail</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="email"
					placeholder="E Mail"
					value={updatedCustomer.email}
					onChange={(e) => setFieldUpdatedCustomer("email", e.target.value)}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Adres</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="address"
					placeholder="Adres"
					value={updatedCustomer.address}
					onChange={(e) => setFieldUpdatedCustomer("address", e.target.value)}
				/>
			</Stack>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button sx={{ flex: 1 }} variant="outlined" onClick={handleCloseDialog}>
					İptal
				</Button>
				<Button disabled={isDisabled} sx={{ flex: 1 }} variant="contained" onClick={handleClickSave}>
					Kaydet
				</Button>
			</Stack>
		</Stack>
	);
}

export default UpdateCustomer;