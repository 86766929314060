import { Backdrop, CircularProgress } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import { useApi } from "../../provider/api-provider";

const CustomCircularProgress = () => {
	const { loading } = useApi();
	return (
		<Backdrop
			sx={{zIndex: (theme) => theme.zIndex.modal + 1 }}
			open={loading}
		>
			<CircularProgress sx={{ color: "white" }} />
		</Backdrop>
	);
}

export default CustomCircularProgress;