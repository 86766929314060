import { AxiosInstance } from "axios";
import { Role } from "../models/role";
import { getAllRolesURL } from "../global";

export interface RoleServiceType {
	getAllRoles: () => Promise<Role[]>
}

export const RoleService = ({ api }: { api: AxiosInstance }): RoleServiceType => {

	const getAllRoles = () => {
		return api.get<void, Role[]>(getAllRolesURL);
	}

	return {
		getAllRoles,
	}

}