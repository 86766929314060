import { Route, Routes } from "react-router-dom";
import { pages } from "../global";
import uuid from "react-uuid";
import Error from "../pages/error";
import Layout from "../layout/layout";
import Login from "../pages/login";
import ProtectedRoute from "./protected-route";

const Routing = () => {

	return (
		<Routes>
			<Route path="/error" element={<Error />} />
			<Route path="/login" element={<Login />} />
			<Route element={<Layout />}>
				{
					pages.map((page) => (
						<Route
							key={uuid()}
							path={page.path}
							element={
								<ProtectedRoute roles={page.roles}>
									{page.element}
								</ProtectedRoute>
							}
						/>
					))
				}
			</Route>
		</Routes>
	);
};

export default Routing;