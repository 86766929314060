// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link {
	text-decoration: none;
	padding: 3px 10px;
	align-items: center;
	gap: 5px;
	border-radius: 7px;
	color: inherit;
	width: 100%;
	display: flex;
}

.nav-link.active {
	border: 0.5px solid rgba(0, 0, 0, 0.04);
	background: #FFF;
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
}`, "",{"version":3,"sources":["webpack://./src/styles/nav-bar.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,iBAAiB;CACjB,mBAAmB;CACnB,QAAQ;CACR,kBAAkB;CAClB,cAAc;CACd,WAAW;CACX,aAAa;AACd;;AAEA;CACC,uCAAuC;CACvC,gBAAgB;CAChB,4EAA4E;AAC7E","sourcesContent":[".nav-link {\r\n\ttext-decoration: none;\r\n\tpadding: 3px 10px;\r\n\talign-items: center;\r\n\tgap: 5px;\r\n\tborder-radius: 7px;\r\n\tcolor: inherit;\r\n\twidth: 100%;\r\n\tdisplay: flex;\r\n}\r\n\r\n.nav-link.active {\r\n\tborder: 0.5px solid rgba(0, 0, 0, 0.04);\r\n\tbackground: #FFF;\r\n\tbox-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
